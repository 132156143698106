export const QUESTIONNAIRE_ID_SKIN_PASSWORDLESS =
  process.env.ENV_QUESTIONNAIRE_ID_SKIN || '65413defb9dcec058d971c38'
export const QUESTIONNAIRE_ID_EYE_CREAM_PASSWORDLESS =
  process.env.ENV_QUESTIONNAIRE_ID_EYE_CREAM || '64ac784b6e0d31411c4e613d'
export const QUESTIONNAIRE_ID_SERUM =
  process.env.ENV_QUESTIONNAIRE_ID_SERUM || '64d27ec50b96607becb07c5d'

export const QUESTIONNAIRE_ID_ACNE =
  process.env.ENV_QUESTIONNAIRE_ID_ACNE || '66d0a9937ac702eb6f8fff7b' //'66c6066a180cff6a12703ad0'

export const ACNE_QUESTIONNAIRE_KEY_NAME = '66d0a7b526ae98143f2416bd' // name id in questions/answers only for acne quiz
export const QUESTIONNAIRE_KEY_NAME = '5da5128371447f00158cf098' // name id in questions/answers
export const QUESTIONNAIRE_KEYS_NAME = [QUESTIONNAIRE_KEY_NAME, ACNE_QUESTIONNAIRE_KEY_NAME]

export const QUESTIONNAIRE_KEY_ZIPCODE = '5b459e91dc0957fe3429d9e6' // name id in questions/answers
export const QUESTIONNAIRE_KEY_PHONE = '5e5ef18a9630d258f60c6494' // name id in questions/answers
export const QUESTIONNAIRE_KEY_EMAIL = '64ad747dbe242f308af8667d' // name id in questions/answers
export const QUESTIONNAIRE_KEY_PASSWORD = '5da5f9d7dc8d26001511c958' // name id in questions/answers

export const QUESTION_STYLE = {
  SELECT_MULTIPLE_DOUBLE: 'SelectMultipleDouble', // the default version at the moment that has TWO ALIGNED COLUMNS for MOBILE and THREE ALIGNED COLUMNS for DESKTOP'
  SELECT_MULTIPLE_LONG: 'SelectMultipleStandardLong', //version with ONE COLUMN for MOBILE and TWO COLUMNS for DESKTOP
  SELECT_MULTIPLE_SHORT: 'SelectMultipleShort', // version with options shown in a flex container with wrap
  SELECT_MULTIPLE_STANDARD: 'SelectMultipleStandard' //currently the same that SELECT_MULTIPLE_SHORT
}

export const QUIZ_ANALYTICS_EVENT_PREFIX = 'assessment_'

export const SKIN_QUESTIONNAIRE_URI = '/quiz'
export const SKIN_QUESTIONNAIRE_OPENING_PAGE_URI = '/quiz/start'
export const SKIN_QUESTIONNAIRE_FIRST_QUESTION_URI = '/quiz/concern'
export const EYE_QUESTIONNAIRE_URI = '/quiz/eye'
export const EYE_QUESTIONNAIRE_OPENING_PAGE_URI = '/quiz/eye/start'
export const EYE_QUESTIONNAIRE_FIRST_QUESTION_URI = '/quiz/eye/concern'
export const SERUM_QUESTIONNAIRE_URI = '/quiz/serum'
export const SERUM_QUESTIONNAIRE_OPENING_PAGE_URI = '/quiz/serum/start'
export const SERUM_QUESTIONNAIRE_FIRST_QUESTION_URI = '/quiz/serum/concern'

export const ACNE_QUESTIONNAIRE_BASE_PATH = '/quiz/acne'
export const ACNE_QUESTIONNAIRE_FIRST_QUESTION_URI = '/quiz/acne/concern'
export const ACNE_QUESTIONNAIRE_OPENING_PAGE_URI = '/quiz/acne/start'
export const ACNE_TESTIMONIALS_PAGE_NAME = 'acne_testimonials'

export const QUESTIONNAIRE_URIS = [
  SKIN_QUESTIONNAIRE_URI,
  SKIN_QUESTIONNAIRE_FIRST_QUESTION_URI,
  EYE_QUESTIONNAIRE_URI,
  EYE_QUESTIONNAIRE_FIRST_QUESTION_URI,
  SERUM_QUESTIONNAIRE_URI,
  SERUM_QUESTIONNAIRE_FIRST_QUESTION_URI,
  ACNE_QUESTIONNAIRE_FIRST_QUESTION_URI,
  `${ACNE_QUESTIONNAIRE_BASE_PATH}/${ACNE_TESTIMONIALS_PAGE_NAME}`
]

export const TEMPLATE_OF_QUESTIONNAIRE_INITIAL_REDUX_STATE = {
  answers: { version: 0, id: QUESTIONNAIRE_ID_SKIN_PASSWORDLESS, variant: 'A' },
  ingredients: [],
  completed: false,
  // pending: null,
  questions: [],
  questions_b: [],
  version_a: {},
  version_b: {},
  invalid: null,

  zip_qid: null,
  congrats_page: ''
}

export const CONCERN_QUESTION = {
  _id: '5ee87a1fa0f02600153bbc55',
  question: {
    items: [
      {
        text: 'Sensitivity',
        value: 0
      },
      {
        text: 'Redness',
        value: 1
      },
      {
        text: 'Fine lines or wrinkles',
        value: 2
      },
      {
        text: 'Loss of firmness or elasticity',
        value: 3
      },
      {
        text: 'Hyperpigmentation',
        value: 4
      },
      {
        text: 'Acne',
        value: 5
      },
      {
        text: 'Dryness',
        value: 6
      },
      {
        text: 'Other',
        value: 7
      }
    ],
    _id: '5bd0f2a48e113000132de32d',
    heading: 'What are your main skin concerns?',
    type: 'SelectMultiple',
    group: 'skin Concerns',
    name: 'concern',
    validityCheck: false,
    transition: {
      title: true,
      status: "3 minutes to your best skincare, let's get started!"
    },
    transitionB: {
      title: true,
      status: "3 minutes to your best skincare, let's go!"
    },
    createdAt: '2018-11-02T20:52:37.362Z',
    updatedAt: '2019-11-06T23:24:02.956Z',
    __v: 0,
    subHeading: null,
    groupImage: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-skin-concerns.svg'
  }
}

export const CONCERN_QUESTION_EYE = {
  _id: '620e9a46f6672000168ce2ed',
  question: {
    _id: '620be33b078ac800166fe6be',
    items: [
      {
        text: 'Sensitivity or Redness',
        value: 0
      },
      {
        text: 'Fine Lines and Wrinkles',
        value: 1
      },
      {
        text: 'Dark Circles',
        value: 2
      },
      {
        text: 'Drooping or Loss of Elasticity',
        value: 3
      },
      {
        text: 'Uneven Skin Tone',
        value: 4
      },
      {
        text: 'Puffiness or Eye Bags',
        value: 5
      },
      {
        text: 'Dryness',
        value: 6
      }
    ],
    heading: 'What are your top eye-area skin concerns?',
    type: 'SelectMultiple',
    group: 'Skin Concerns',
    groupImage: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-skin-concerns.svg',
    name: 'concern',
    validityCheck: false,
    transitionB: {
      title: true,
      status: "3 minutes to your Personalized Eye Cream Duo. Let's go!"
    },
    createdAt: '2022-02-15T17:30:35.923+0000',
    updatedAt: '2022-02-17T03:19:46.341+0000',
    __v: 0
  }
}

export const CONCERN_QUESTION_SERUM = {
  _id: '5ee87a1fa0f02600153bbc55',
  question: {
    items: [
      {
        text: 'Sensitivity',
        value: 0
      },
      {
        text: 'Redness',
        value: 1
      },
      {
        text: 'Fine lines or wrinkles',
        value: 2
      },
      {
        text: 'Loss of firmness or elasticity',
        value: 3
      },
      {
        text: 'Hyperpigmentation',
        value: 4
      },
      {
        text: 'Acne',
        value: 5
      },
      {
        text: 'Dryness',
        value: 6
      },
      {
        text: 'Other',
        value: 7
      }
    ],
    _id: '5bd0f2a48e113000132de32d',
    heading: 'What are your main skin concerns?',
    type: 'SelectMultiple',
    group: 'skin Concerns',
    name: 'concern',
    validityCheck: false,
    transition: {
      title: true,
      status: "3 minutes to your best skincare, let's get started!"
    },
    transitionB: {
      title: true,
      status: "3 minutes to your best skincare, let's go!"
    },
    createdAt: '2018-11-02T20:52:37.362Z',
    updatedAt: '2019-11-06T23:24:02.956Z',
    __v: 0,
    subHeading: null,
    groupImage: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-skin-concerns.svg'
  }
}

//export const CONCERN_QUESTION_ACNE = CONCERN_QUESTION
export const CONCERN_QUESTION_ACNE = {
  _id: '66db487663dcd9005d493f36',
  question: {
    items: [
      {
        text: 'Pustules',
        subline: 'Pimples containing pus, red at the base',
        value: 0
      },
      {
        text: 'Blackheads (Comedones)',
        subline: 'Clogged pores that appear black',
        value: 1
      },
      {
        text: 'Whiteheads (Comedones)',
        subline: 'Clogged pores with white surface',
        value: 2
      },
      {
        text: 'Cysts',
        subline: 'Deep, painful, pus-filled lumps',
        value: 3
      },
      {
        text: 'Nodules',
        subline: 'Large, painful lumps under the skin',
        value: 4
      }
    ],
    _id: '66cf43b596df82814aaa89ec',
    heading: 'Which symptoms concern you the most?',
    type: 'SelectMultiple',
    group: 'SKIN CONCERNS',
    name: 'concern',
    style: QUESTION_STYLE.SELECT_MULTIPLE_LONG,
    validityCheck: false,
    createdAt: '2024-08-28T15:35:17.464Z',
    updatedAt: '2024-09-06T14:38:39.232Z',
    __v: 0,
    subHeading: 'Choose all that apply',
    groupImage: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-skin-concerns.svg'
  }
}
