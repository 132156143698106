import { QUESTIONNAIRE_ID_ACNE, QUESTIONNAIRE_ID_SKIN_PASSWORDLESS } from './questionnaire'

export const ACNE_QUESTION_NAME__OTHER = 'other'
export const ACNE_QUESTION_NAME__CONSUMPTION = 'consumption'

export const SKIN_QUESTION_NAME__ELASTICITY_VOLUME = 'elasticity_volume'
export const SKIN_QUESTION_NAME__Q1_FIRMNESS = 'Q1_firmness'

export const SKIN_QUESTION_NAME__REDNESS_VESSELS = 'redness_vessels'
export const SKIN_QUESTION_NAME__Q1_REDNESS = 'Q1_redness'

export const SKIN_QUESTION_NAME__PIGMENTATION_ACNE = 'pigmentation_acne'
export const SKIN_QUESTION_NAME__Q1_PIGMENTATION = 'Q1_pigmentation'

export const SKIN_QUESTION_NAME__SENSITIVITY_TIGHTNESS = 'sensitivity_tightness'
export const SKIN_QUESTION_NAME__Q1_SENSITIVITY = 'Q1_sensitivity'

export const SKIN_QUESTION_NAME__WRINLKE_TEXTURE = 'wrinkle_texture'
export const SKIN_QUESTION_NAME__Q1_WRINLKES = 'Q1_wrinkles'

export const SKIN_QUESTION_NAME__DRYNESS_PATCHY = 'dryness_patchy'
export const SKIN_QUESTION_NAME__Q1_DRYNESS = 'Q1_dryness'

export const SKIN_QUESTION_NAME__ACNE_FREQ_PAPULE = 'acne_freq_papule'
export const SKIN_QUESTION_NAME__Q1_ACNE = 'Q1_acne'

/*
This object is created to map some info to a specific question (by question name) of a specific questionnaire (by questionnaire id).
the question name refers to the questions that next page is a transition page.
 */
export default {
  [QUESTIONNAIRE_ID_ACNE]: {
    [ACNE_QUESTION_NAME__OTHER]: {
      transitionName: 'science-backed-ingredients',
      isFullscreenTransition: true
    },
    [ACNE_QUESTION_NAME__CONSUMPTION]: {
      transitionName: 'simple-3-product-essential-routine',
      isFullscreenTransition: true
    }
  },

  [QUESTIONNAIRE_ID_SKIN_PASSWORDLESS]: {
    [SKIN_QUESTION_NAME__ELASTICITY_VOLUME]: {
      transitionName: 'firmness_transition',
      isFullscreenTransition: false
    },
    [SKIN_QUESTION_NAME__Q1_FIRMNESS]: {
      // this is the first question of firmness concern, and it is followed by the transition page only in the case user selects "NOT AT ALL CONCERNED" answer
      transitionName: 'firmness_transition',
      isFullscreenTransition: false
    },

    [SKIN_QUESTION_NAME__REDNESS_VESSELS]: {
      transitionName: 'redness_transition',
      isFullscreenTransition: false
    },
    [SKIN_QUESTION_NAME__Q1_REDNESS]: {
      // this is the first question of redness concern, and it is followed by the transition page only in the case user selects "NOT AT ALL" answer
      transitionName: 'redness_transition',
      isFullscreenTransition: false
    },

    [SKIN_QUESTION_NAME__PIGMENTATION_ACNE]: {
      transitionName: 'pigmentation_transition',
      isFullscreenTransition: false
    },
    [SKIN_QUESTION_NAME__Q1_PIGMENTATION]: {
      // this is the first question of pygmentation concern, and it is followed by the transition page only in the case user selects "NOT CONCERNED" answer
      transitionName: 'pigmentation_transition',
      isFullscreenTransition: false
    },

    [SKIN_QUESTION_NAME__SENSITIVITY_TIGHTNESS]: {
      transitionName: 'sensitivity_transition',
      isFullscreenTransition: false
    },
    [SKIN_QUESTION_NAME__Q1_SENSITIVITY]: {
      // this is the first question of sensitivity concern, and it is followed by the transition page only in the case user selects "NOT SENSITIVE" answer
      transitionName: 'sensitivity_transition',
      isFullscreenTransition: false
    },

    [SKIN_QUESTION_NAME__WRINLKE_TEXTURE]: {
      transitionName: 'wrinkles_transition',
      isFullscreenTransition: false
    },
    [SKIN_QUESTION_NAME__Q1_WRINLKES]: {
      // this is the first question of wrinkles concern, and it is followed by the transition page only in the case user selects "NOT CONCERNED" answer
      transitionName: 'wrinkles_transition',
      isFullscreenTransition: false
    },

    [SKIN_QUESTION_NAME__DRYNESS_PATCHY]: {
      transitionName: 'dryness_transition',
      isFullscreenTransition: false
    },
    [SKIN_QUESTION_NAME__Q1_DRYNESS]: {
      // this is the first question of dryness concern, and it is followed by the transition page only in the case user selects "NEVER" answer
      transitionName: 'dryness_transition',
      isFullscreenTransition: false
    },

    [SKIN_QUESTION_NAME__ACNE_FREQ_PAPULE]: {
      transitionName: 'acne_transition',
      isFullscreenTransition: false
    },
    [SKIN_QUESTION_NAME__Q1_ACNE]: {
      // this is the first question of acne concern, and it is followed by the transition page only in the case user selects "NEVER" answer
      transitionName: 'acne_transition',
      isFullscreenTransition: false
    }
  }
  /*
    TO DEFINE A NEW TRANSITION WE NEED TO:
     add a new key using as key the question name whose next question will be the transition page. It should be add in the corresponding questionnaire id object
     In the new entry we define the transition name and if the transition is a fullscreen transition or not.

    AFTER DEFINING THE NEW TRANSITION, WE NEED TO MODIFY src/components/router/index.js to set-up the new transition routes
    We just need to call createLoadableTransitionComponent(), passing the corresponding params. See comments at src/components/router/index.js
*/
}
